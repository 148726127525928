import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const GridComponent = (props) => {
  const { children } = props;
  return (
    <Grid
      container
      direction="column"
    >
      {
        children.map((child) => (
          <Grid
            key={`item-${child.key}`}
            item
          >
            {child}
          </Grid>
        ))
      }
    </Grid>
  );
};

GridComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

GridComponent.defaultProps = {
  children: [],
};


export default GridComponent;
