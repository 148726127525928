import FormComponent from './FormComponent';
import TextFieldComponent from './TextFieldComponent';
import SelectorComponent from './SelectorComponent';
import DatePickerComponent from './DatePickerComponent';
import GridComponent from './GridComponent';
import ButtonComponent from './ButtonComponent';
import AppliedRoute from './AppliedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import LabelComponent from './LabelComponent';

const Components = {
  FormComponent,
  TextFieldComponent,
  SelectorComponent,
  DatePickerComponent,
  GridComponent,
  ButtonComponent,
  AppliedRoute,
  UnauthenticatedRoute,
  AuthenticatedRoute,
  LabelComponent,
};

export default Components;
