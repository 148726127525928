import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const SelectorComponent = (props) => {
  const {
    label,
    options,
    value,
    onChange,
  } = props;
  return (
    <TextField
      select
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
  );
};

SelectorComponent.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

SelectorComponent.defaultProps = {
  options: [],
};


export default SelectorComponent;
