import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
`;

const RowComponent = (props) => {
  const { children } = props;
  return (
    <Row key="row">{children}</Row>
  );
};

RowComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default RowComponent;
