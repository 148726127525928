import React, { useState, useEffect } from 'react';
import {
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import Components from './components';
import LayoutComponent from './LayoutComponent';
import HomeComponent from './HomeComponent';
import ContractDetailsComponent from './ContractDetailsComponent';
import ContractsComponent from './ContractsComponent';
import ForecastComponent from './ForecastComponent';
import ThemeProviderComponent from './ThemeProviderComponent';
import LoginComponent from './LoginComponent';

function App(props) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const appProps = { isAuthenticated, userHasAuthenticated };

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  useEffect(() => {
    onLoad();
  }, []);

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    props.history.push('/login');
  }

  return (
    !isAuthenticating && (
      <ThemeProviderComponent>
        <LayoutComponent>
          <Switch>
            <Components.AuthenticatedRoute exact path="/" component={HomeComponent} appProps={appProps} />
            <Components.UnauthenticatedRoute exact path="/login" component={LoginComponent} appProps={appProps} />
            <Components.AuthenticatedRoute exact path="/contracts/:contractId" component={ContractDetailsComponent} appProps={appProps} />
            <Components.AuthenticatedRoute exact path="/contracts" component={ContractsComponent} appProps={appProps} />
            <Components.AuthenticatedRoute exact path="/forecast" component={ForecastComponent} appProps={appProps} />
            <Components.AuthenticatedRoute render={() => <Redirect to="/" />} appProps={appProps} />
          </Switch>
          <Components.ButtonComponent onClick={handleLogout}>
            Abmelden
          </Components.ButtonComponent>
        </LayoutComponent>
      </ThemeProviderComponent>
    )
  );
}

App.propTypes = {
  history: PropTypes.arrayOf().isRequired,
};


export default withRouter(App);
