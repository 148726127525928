import React from "react";
import styled from "styled-components";
import PropTypes from 'prop-types';
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";

const headerHeight = 100;

const Layout = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  color: white;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(49, 64, 76);
`;

const Header = styled.div`
  height: ${headerHeight}px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 999;
  background-color: white;
  border-bottom: 1px solid grey;
  background-color: rgb(31, 41, 49);
`;

const Blackout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000025; 
  height: 100%;
  width: 100%;
`;

const Body = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  height: calc(100% - ${props => props.showHeader ? headerHeight : 0 }px);
  position: relative;
  display: flex;
  top: ${props => props.showHeader ? headerHeight : 0}px;
  bottom: 0;
  flex-direction: column;
  `;
  
  const Content = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
`;
const Footer = styled.div`
  border-top: 1px solid grey;
  background-color: rgb(39,   51, 61);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0;
`;

class LayoutComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      windowWidth: 0,
      windowHeight: 0,
      containerWidth: 0,
      containerHeight: 0
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  showFooter() {
    const MIN_WIDTH = 768;
    return this.state.windowWidth >= MIN_WIDTH;
  }

  showHeader() {
    const MIN_WIDTH = 768;
    return this.state.windowWidth >= MIN_WIDTH;
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;
    this.setState({ windowWidth, windowHeight });
  }

  render() {
    const { handleLogout } = this.props;
    return (
      <Layout key="layout">
        <Background id="background" key="background" />
          {this.showHeader() ? 
            <Header id="header">
              <HeaderComponent handleLogout={handleLogout} /> 
              <Blackout/>
            </Header> :
          ""}
          <Body id="body" key="body" showHeader={this.showHeader()}>
            <Content id="content" key="content" >
              {this.props.children}
            </Content>
            {this.showFooter() ? 
              <Footer id="footer" key="footer">
                <FooterComponent />
              </Footer> :
            ""}
          </Body>
      </Layout>
    );
  }
}

LayoutComponent.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};

export default LayoutComponent;
