import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import Components from './components';

const LoginComponent = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      await Auth.signIn(email, password);
      props.userHasAuthenticated(true);
    } catch (e) {
      alert(e.message);
    }
  }

  return (
    <Components.FormComponent onSubmit={handleSubmit}>
      <Components.GridComponent>
        <Components.TextFieldComponent
          label="Email"
          type="email"
          value={email}
          onChange={(value) => setEmail(value)}
        />
        <Components.TextFieldComponent
          label="Passwort"
          type="password"
          value={password}
          onChange={(value) => setPassword(value)}
        />
        <Components.ButtonComponent type="submit" disabled={!validateForm()}>
          Anmelden
        </Components.ButtonComponent>
      </Components.GridComponent>
    </Components.FormComponent>
  );
};

LoginComponent.propTypes = {
  userHasAuthenticated: PropTypes.bool.isRequired,
};

export default LoginComponent;
