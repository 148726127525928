import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Components from './components';
import UNITS from './units';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));


const PaymentTermInputFormComponent = (props) => {
  const classes = useStyles();
  const {
    amount,
    onAmountChange,
    rhythm,
    onRhythmChange,
    unit,
    onUnitChange,
    startDate,
    onStartChange,
    endDate,
    onEndChange,
  } = props;
  return (
    <Components.FormComponent className={classes.root}>
      <Components.GridComponent>
        <Components.TextFieldComponent
          key="amount"
          label="Betrag"
          value={`${amount}`}
          onChange={onAmountChange}
        />
        <Components.SelectorComponent
          key="unit"
          label="Einheit"
          options={[
            {
              label: 'täglich',
              value: UNITS.DAY,
            },
            {
              label: 'wöchentlich',
              value: UNITS.WEEK,
            },
            {
              label: 'monatlich',
              value: UNITS.MONTH,
            },
            {
              label: 'jährlich',
              value: UNITS.YEAR,
            },
          ]}
          value={unit}
          onChange={onUnitChange}
        />
        <Components.TextFieldComponent
          key="rhythm"
          label="Rhythmus"
          value={`${rhythm}`}
          onChange={onRhythmChange}
        />
        <Components.DatePickerComponent
          key="start"
          label="Start"
          selectedDate={startDate}
          onChange={onStartChange}
        />
        <Components.DatePickerComponent
          key="end"
          label="Ende"
          selectedDate={endDate}
          onChange={onEndChange}
        />
      </Components.GridComponent>
    </Components.FormComponent>
  );
};

PaymentTermInputFormComponent.propTypes = {
  amount: PropTypes.number.isRequired,
  onAmountChange: PropTypes.func.isRequired,
  rhythm: PropTypes.number.isRequired,
  onRhythmChange: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
  onUnitChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Moment).isRequired,
  onStartChange: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Moment).isRequired,
  onEndChange: PropTypes.func.isRequired,
};

export default PaymentTermInputFormComponent;
