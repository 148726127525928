import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import UsericonComponent from './UsericonComponent';
import ActionbarComponent from './ActionbarComponent';
import LogoutComponent from './LogoutComponent';

const Header = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  height: 100%;
`;

const HeaderComponent = (props) => {
  const { handleLogout } = props;
  return (
    <Header id="header" key="header">
      <UsericonComponent />
      <ActionbarComponent />
      <LogoutComponent handleLogout={handleLogout} />
    </Header>
  );
};

HeaderComponent.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};


export default HeaderComponent;
