import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Components from './components';

const Spacer = styled.div`
  height: 50px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 1000,
    },
  },
}));


const ContractsInputFormComponent = (props) => {
  const classes = useStyles();
  const {
    contracts,
    onContractsChange,
  } = props;
  return (
    <Components.FormComponent className={classes.root}>
      <Components.TextFieldComponent
        key="contracts"
        label="Verträge"
        value={contracts}
        onChange={onContractsChange}
        multiline
        rows={10}
      />
      <Spacer />
      <div>Example:</div>
      <div>a,Partnerportal,Münchner Singles GmbH,15,m,1,01.01.2020,31.01.2021</div>
      <Spacer />
      <div>Syntax:</div>
      <div>Every contract needs to be placed on a separate line</div>
      <div>type,description,contact,amount,unit,rhythm,start,end</div>
      <div>Possible Value:</div>
      <div>{'type in {e, a}'}</div>
      <div>description as text</div>
      <div>contact as text</div>
      <div>amount as number with 0 digits</div>
      <div>{'unit in {DAY, WEEK, MONTH, YEAR}'}</div>
      <div>rhythm as number with 0 digits</div>
      <div>start as date with pattern dd.mm.yyyy</div>
      <div>end as date with pattern dd.mm.yyyy</div>
      <Spacer />
    </Components.FormComponent>
  );
};

ContractsInputFormComponent.propTypes = {
  contracts: PropTypes.string.isRequired,
  onContractsChange: PropTypes.func.isRequired,
};

export default ContractsInputFormComponent;
