import React from 'react';
import { PropTypes } from "prop-types";
import styled from 'styled-components';

const HeadingText = styled.div`
  font-size: 40px;
`;

const HeadingComponent = (props) => {
  const { children: text } = props;
  return (
    <HeadingText>
      {text}
    </HeadingText>
  );
}

HeadingComponent.propTypes = {
  children: PropTypes.string.isRequired,
};

export default HeadingComponent;