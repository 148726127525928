import React from 'react';
import PropTypes from 'prop-types';

const LabelComponent = (props) => {
  const { children: text } = props;
  return (
    <div>{text}</div>
  );
};


LabelComponent.propTypes = {
  children: PropTypes.string.isRequired,
};

export default LabelComponent;
