import React from 'react';
import { Button } from '@material-ui/core/';
import PropTypes from 'prop-types';


const ButtonComponent = (props) => {
  const {
    href,
    children,
    type,
    onClick,
  } = props;
  return (
    <Button
      href={href}
      variant="contained"
      color="primary"
      type={type}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

ButtonComponent.propTypes = {
  href: PropTypes.string,
  children: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonComponent.defaultProps = {
  href: '',
  children: '',
  type: '',
  onClick: () => {},
};


export default ButtonComponent;
