import React from 'react';
import PropTypes from 'prop-types';

const FormComponent = (props) => {
  const { children, className, onSubmit } = props;
  return (
    <form className={className} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

FormComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};

FormComponent.defaultProps = {
  children: '',
  className: '',
  onSubmit: () => {},
};

export default FormComponent;
