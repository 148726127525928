import React from 'react';
import Components from './components';

const HomeComponent = () => (
  <div>
    <div>Home</div>
    <div>
      <Components.ButtonComponent href="/forecast">Zum Forecast</Components.ButtonComponent>
    </div>
  </div>
);

export default HomeComponent;
