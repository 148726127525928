import React, { useState } from 'react';
import styled from 'styled-components';
import ButtonComponent from './components/ButtonComponent';


const Row = styled.div`
  padding: 10px;
`;

const ContractsComponent = () => {
  const [contracts] = useState([{ id: 1 }, { id: 2 }]);
  return (
    <div>
      {contracts
        .map((contract) => (
          <Row
            key={`contract-${contract.id}`}
          >
            <ButtonComponent
              href={`/contracts/${contract.id}`}
            >
              {`Contract ${contract.id}`}
            </ButtonComponent>
          </Row>
        ))}
    </div>
  );
};

export default ContractsComponent;
