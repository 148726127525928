import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Cell = styled.div`
  padding: 10px;
  width: 100px;
`;

const CellComponent = (props) => {
  const { children } = props;
  return (
    <Cell key="cell">{children}</Cell>
  );
};

CellComponent.propTypes = {
  children: PropTypes.string.isRequired,
};


export default CellComponent;
