import React from "react";
import styled from "styled-components";

const Footer = styled.div`
  flex: 0 0 auto;
  height: 50px;
`;

const FooterComponent = () => (
  <Footer id="footer" key="footer">
    Footer
  </Footer>
);

export default FooterComponent;
