import React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import PropTypes from 'prop-types';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: blue,
  },
});


const ThemeProviderComponent = (props) => {
  const { children } = props;
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

ThemeProviderComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProviderComponent;
