const dev = {
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_NwQ37aFgb',
    APP_CLIENT_ID: '3shfqavhtpbl5uc1klfcq4s7n1',
    IDENTITY_POOL_ID: 'us-east-1:a58cab40-4268-47c4-a5c3-4a3d67f903a1',
  },
};

const prod = {
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_NwQ37aFgb',
    APP_CLIENT_ID: '3shfqavhtpbl5uc1klfcq4s7n1',
    IDENTITY_POOL_ID: 'us-east-1:a58cab40-4268-47c4-a5c3-4a3d67f903a1',
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
