import React from 'react';
import PropTypes from 'prop-types';
import RowComponent from './RowComponent';
import CellComponent from './CellComponent';

const getEndDate = (slice) => slice.endDate.format('DD.MM.YYYY');

const TimelineComponent = (props) => {
  const { timeSlices } = props;
  return (
    <RowComponent key="timeline">
      {
        timeSlices.map((slice) => (
          <CellComponent key={getEndDate(slice)}>
            {getEndDate(slice)}
          </CellComponent>
        ))
      }
    </RowComponent>
  );
};

TimelineComponent.propTypes = {
  timeSlices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TimelineComponent;
