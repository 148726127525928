import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CellComponent from './CellComponent';
import RowComponent from './RowComponent';
import UNITS from './units';

const CashflowComponent = (props) => {
  const { paymentTerm, timeSlices } = props;
  return (
    <RowComponent>
      {
        timeSlices.map((slice) => (
          <CellComponent key={slice.endDate}>
            {`${CashflowComponent.calculateCashflow(paymentTerm, slice)}`}
          </CellComponent>
        ))
      }
    </RowComponent>
  );
};

CashflowComponent.propTypes = {
  paymentTerm: PropTypes.shape({
    amount: PropTypes.number.isRequired,
  }).isRequired,
  timeSlices: PropTypes.arrayOf(
    PropTypes.shape({
      endDate: PropTypes.instanceOf(moment).isRequired,
    }).isRequired,
  ).isRequired,
};

const calculateNumberOfPaymentIntervals = (unitCount, rhythm = 1) => Math.trunc(unitCount / rhythm);

const getDateCorrection = (paymentTerm, endDateOfTimeSlice) => {
  switch (paymentTerm.unit) {
    case UNITS.MONTH:
    case UNITS.YEAR:
      if (
        paymentTerm.startDate.date() <= endDateOfTimeSlice.endOf('month').date()
      ) return 0;
      return paymentTerm.startDate.date() - endDateOfTimeSlice.endOf('month').date();

    default:
      return 0;
  }
};

const numberOfPayments = (paymentTerm, timeSlice) => {
  const endDate = paymentTerm.endDate.isBefore(timeSlice.endDate)
    ? paymentTerm.endDate
    : timeSlice.endDate;

  const { unit: paymentTermUnit } = paymentTerm;

  const CALCULATION_UNITS = {
    [UNITS.DAY]: 'days',
    [UNITS.WEEK]: 'weeks',
    [UNITS.MONTH]: 'months',
    [UNITS.YEAR]: 'years',
  };

  const numberOfPreviousPaymentUnits = Math.max(
    moment.duration(
      moment(timeSlice.startDate)
      // need to clone since subtract() mutates the original moment
        .subtract(1, 'days')
        .add(
          getDateCorrection(
            paymentTerm,
            moment(timeSlice.startDate)
              .subtract(1, 'days'),
          ),
          'days',
        )
        .diff(
          paymentTerm.startDate,
          CALCULATION_UNITS[paymentTermUnit],
        ),
    ),
    0,
  );

  const numberOfPreviousPaymentIntervals = calculateNumberOfPaymentIntervals(
    numberOfPreviousPaymentUnits,
    paymentTerm.rhythm,
  );

  const totalNumberOfPaymentUnits = Math.max(
    moment.duration(
      moment(endDate)
        .add(
          getDateCorrection(paymentTerm, timeSlice.endDate),
          'days',
        )
        .diff(
          paymentTerm.startDate,
          CALCULATION_UNITS[paymentTermUnit],
        ),
    ),
    0,
  );

  const totalNumberOfPaymentIntervals = calculateNumberOfPaymentIntervals(
    totalNumberOfPaymentUnits,
    paymentTerm.rhythm,
  );

  return totalNumberOfPaymentIntervals
    - numberOfPreviousPaymentIntervals
    + paymentTerm.startDate.isSameOrAfter(timeSlice.startDate, 'day');
};

CashflowComponent.calculateCashflow = (paymentTerm, timeSlice) => {
  if (paymentTerm.startDate.isAfter(timeSlice.endDate)) return 0;
  if (paymentTerm.endDate.isBefore(timeSlice.startDate)) return 0;
  return numberOfPayments(paymentTerm, timeSlice) * paymentTerm.amount;
};


export default CashflowComponent;
