import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function AuthenticatedRoute({ component: C, appProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (appProps.isAuthenticated
        ? <C {...props} {...appProps} />
        : (
          <Redirect
            to={`/login?redirect=${props.location.pathname}${props.location.search}`}
          />
        ))}
    />
  );
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.node.isRequired,
  appProps: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};
