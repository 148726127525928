import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const TextFieldComponent = (props) => {
  const {
    label,
    value,
    onChange,
    type,
    multiline,
    rows,
  } = props;
  return (
    <TextField
      label={label}
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      multiline={multiline}
      rows={multiline ? rows : 1}
    />
  );
};

TextFieldComponent.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};

TextFieldComponent.defaultProps = {
  value: '',
  onChange: () => {},
  type: '',
  multiline: undefined,
  rows: 5,
};

export default TextFieldComponent;
