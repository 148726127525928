import React from 'react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import Moment from 'moment';

const DatePickerComponent = (props) => {
  const {
    label,
    selectedDate,
    onChange,
  } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        format="DD.MM.YYYY"
        id="date-picker-inline"
        label={label}
        value={selectedDate}
        onChange={(e) => onChange(e)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePickerComponent.propTypes = {
  label: PropTypes.string.isRequired,
  selectedDate: PropTypes.instanceOf(Moment).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DatePickerComponent;
