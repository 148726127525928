import React from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import HeadingComponent from './HeadingComponent';


const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Column = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`;
  
  const TopRow = styled.div`
    height: 100px;
  `;

    
  const Heading = styled.div`
    height: 100px;
    color: white;
  `;

  const InformationAreaFrame = styled.div`
    position: relative;
    flex: 1 1 auto;
  `;

  const InformationAreaTop = styled.div`
    background-color: white;
    height: 57px;
    width: calc(100% - 57px);
    right: 0px;
    top: 0;
    position: absolute;
    transform: rotate(0);

  `;

  const InformationAreaCorner = styled.div`
    background-color: white;
    width: 80px;
    height: 80px;
    top: 17px;
    left: 17px;
    transform: rotate(45deg);
    position: absolute;
  `;
  
  const InformationArea = styled.div`
    padding: 80px;
    background-color: white;
    position: absolute;
    top: 57px;
    left: 0;
    right: 0;
    bottom: 0;
`;

const ContractDetailsComponent = () => {
  const { contractId } = useParams();
  return (
    <Container id="container">
      <Column>
        <TopRow id="topRow">
          <Link to="/contracts">
            Zurück zur Vertragsübersicht
          </Link>
        </TopRow>
        <Heading id="heading">
          <HeadingComponent>
            {`Detailansicht zu Vertrag Nr ${contractId}`}
          </HeadingComponent>
        </Heading>
        <InformationAreaFrame>
          <InformationAreaCorner />
          <InformationAreaTop />
          <InformationArea id="informationArea">
            Vertragsdetails werden später hier angezeigt
          </InformationArea>
        </InformationAreaFrame>
      </Column>
    </Container>
  );
}



export default ContractDetailsComponent;
