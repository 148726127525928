import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import TimelineComponent from './TimelineComponent';
import CashflowComponent from './CashflowComponent';
import UNITS from './units';
import PaymentTermInputFormComponent from './PaymentTermInputFormComponent';
import ContractsInputFormComponent from './ContractsInputFormComponent';
import Components from './components';

const DATEFORMAT = 'DD.MM.YYYY';

const paymentTerm = {
  description: 'Test',
  amount: 100,
  unit: UNITS.MONTH,
  rhythm: 1,
  startDate: moment('2020-02-28'),
  endDate: moment('2023-08-31'),
};

const exampleContracts = [
  'a,Partnerportal,Münchner Singles GmbH,15,MONTH,1,01.01.2020,31.01.2021',
  'a,Kaffee Abo,Bäckerei Günter,1,DAY,1,01.01.2020,31.01.2021',
];

const parseContracts = (stringRepresentation) => {
  let contracts = [
    {
      paymentTerms: [],
    },
  ];

  contracts = stringRepresentation
    .split('\n')
    .map((contractString, ix) => {
      const [,
        description,
        contact,
        amount,
        unit,
        rhythm,
        start,
        end,
      ] = contractString.split(',');
      return ({
        id: ix,
        paymentTerms: [
          {
            // type,
            description,
            contact,
            amount: Number(amount),
            unit,
            rhythm: Number(rhythm),
            startDate: moment(start, DATEFORMAT),
            endDate: moment(end, DATEFORMAT),
          },
        ],
      });
    });

  return contracts;
};

const ForecastComponent = () => {
  const initialState = {
    paymentTerm: { ...paymentTerm },
    contracts: exampleContracts.join('\n'),
    timelineStartDate: moment(),
  };
  const [state, setState] = useState(initialState);

  const {
    description,
    amount,
    unit,
    rhythm,
    startDate,
    endDate,
  } = state.paymentTerm;

  const { timelineStartDate } = state;

  const updateState = (increment) => {
    setState({
      ...state,
      ...increment,
      paymentTerm: {
        ...state.paymentTerm,
        ...increment.paymentTerm,
      },
    });
  };

  const contracts = parseContracts(state.contracts);

  const calculatedTimeSlices = [...Array(12).keys()].map(((ix) => (ix === 0
    ? {
      startDate: moment(timelineStartDate),
      endDate: moment(timelineStartDate).endOf('month'),
    }
    : {
      startDate: moment(timelineStartDate).add(ix, 'month').startOf('month'),
      endDate: moment(timelineStartDate).add(ix, 'month').endOf('month'),
    })
  ));


  const Row = styled.div`
    display: flex;
  `;

  return (
    <div>
      <Components.DatePickerComponent
        label="Timeline Start"
        selectedDate={timelineStartDate}
        onChange={(newMoment) => updateState({ timelineStartDate: moment(newMoment, DATEFORMAT) })}
      />
      <ContractsInputFormComponent
        contracts={state.contracts}
        onContractsChange={(value) => updateState({ contracts: value })}
      />
      <hr />
      <PaymentTermInputFormComponent
        amount={amount}
        onAmountChange={(newAmount) => updateState({ paymentTerm: { amount: Number(newAmount) } })}
        rhythm={rhythm}
        onRhythmChange={(newRhythm) => updateState({ paymentTerm: { rhythm: Number(newRhythm) } })}
        unit={unit}
        onUnitChange={(newUnit) => updateState({ paymentTerm: { unit: newUnit } })}
        startDate={startDate}
        onStartChange={(newMoment) => updateState({ paymentTerm: { startDate: newMoment } })}
        endDate={endDate}
        onEndChange={(newMoment) => updateState({ paymentTerm: { endDate: newMoment } })}
      />
      <hr />
      <div>
        {`${description}, ${amount}, ${unit}, ${rhythm}, ${startDate.format(DATEFORMAT)} - ${endDate.format(DATEFORMAT)}`}
      </div>
      <TimelineComponent timeSlices={calculatedTimeSlices} startDate={timelineStartDate} />
      {contracts.map((contract) => (
        <Row
          id="row"
          key={contract.id}
        >
          <Components.LabelComponent>
            {`${contract.paymentTerms[0].contact}, ${contract.paymentTerms[0].description}`}
          </Components.LabelComponent>
          <CashflowComponent
            key={contract.id}
            paymentTerm={contract.paymentTerms[0]}
            timeSlices={calculatedTimeSlices}
          />
        </Row>
      ))}
    </div>
  );
};

ForecastComponent.exampleData = {
  paymentTerm: { ...paymentTerm },
  contracts: exampleContracts,
};

ForecastComponent.UNITS = UNITS;


export default ForecastComponent;
